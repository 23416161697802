import React, { useState, useEffect } from "react";
import logo from "../../Assets/logo-wide.png";
import search from "../../Assets/icons8-search-24.png";
import { useNavigate } from "react-router-dom";
import Drawer from "react-modern-drawer";
import { searchApi, user } from '../../utils/constant';
import { List, MenuItem } from "@mui/material";
import qs from 'qs';
import { LocationOn } from "@mui/icons-material";

const Header = () => {
  const [searchingApi, setSearchingApi] = useState([])
  const [searchingValue, setSearchingValue] = useState()
  const userAddress = JSON.parse(localStorage.getItem("userAddress"));
  const userAddressLatLong = JSON.parse(localStorage.getItem("userAddressLatLong"));
  function myFunction() {
    var x = document.getElementById("divv");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }
  const Navigate = useNavigate();
  const cart = () => {
    Navigate("/checkout");
  };

  const Order = () => {
    Navigate("/myorders");
  };

  const Addresses = () => {
    Navigate("/Savedaddress/header");
  };

  const Feedback = () => {
    Navigate("/addfeedback");
  };

  const Help = () => {
    Navigate("/help");
  };

  const Home = () => {
    Navigate("/");
  };

  const Update = () => {
    Navigate('/profile'
    );
  };

  const Search = () => {
    Navigate("/search")
  }
  const userLogOutApi_f = () => {
    localStorage.clear();
    Navigate('/login')
  }

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  // searchApi api calling
  const searchApi_f = (searchingValue) => {
    if (!searchingValue) {
      return
    }
    searchApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, serach_keyword: searchingValue }))
      .then((response) => {
        setSearchingApi(response?.data?.searchList)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (value) => {
    setSearchingValue(value)
    if (!value) {
      setSearchingApi([])
    }
  }
  useEffect(() => {
    searchApi_f(searchingValue)
  }, [searchingValue]);

 
  return (
    <>
    <div> {userAddress ? 
    <p className='py-1 text-base font-semibold text-center text-gray-600' onClick={(e) => { Navigate('selectAddress') }}><LocationOn /> {userAddress[userAddress?.length -2]} {userAddress[userAddress?.length-1]} </p>
    :
    <p className='py-1 text-base font-semibold  text-center text-gray-600' onClick={(e) => { Navigate('selectAddress') }}><LocationOn /> Select location </p>
    }</div>
      <div className="flex justify-between items-center px-6 py-4 sticky top-0 z-50 bg-white shadow-lg">
        
        <div className="flex gap-3 items-center">
          <img src={logo} className="h-8" onClick={Home}/>
         
        </div>
        <div className="flex gap-3 items-center">

          <div >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-[#ca1e24]" onClick={myFunction}>
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>

          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8 text-[#ca1e24] font-extrabold"
              onClick={toggleDrawer}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
        </div>
      </div>

      <Drawer open={isOpen} onClose={toggleDrawer} direction="right">
        <div>
          <div className="flex gap-3 justify-center items-center p-4 pt-6">
          
            <div className="flex flex-col gap-1">
              <p className="font-semibold text-base">{user?.user_name}</p>
              <p className="text-gray-400">{user?.mob_no}</p>
              <p className="text-violet-500" onClick={Update}>
                Update Profile
              </p>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex gap-2 p-4 border-y" onClick={Order}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
                />
              </svg>
              <p>My Orders</p>
            </div>

            <div className="flex gap-2 p-4 border-b" onClick={Addresses}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9"
                />
              </svg>
              <p>Saved Addresses</p>
            </div>

            <div className="flex gap-2 p-4 border-b" onClick={Feedback}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                />
              </svg>
              <p>Add Feedback</p>
            </div>

            <div className="flex gap-2 p-4 border-b" onClick={Help}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                />
              </svg>
              <p>Help</p>
            </div>

            <div className="flex gap-2 p-4 border-b">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>
              <p onClick={(e) => userLogOutApi_f(e)} >Log out</p>
            </div>
          </div>
        </div>
      </Drawer>

      <div className='m-1 my-3 transi' id="divv" style={{ display: "none" }}>
        <div className='flex justify-around p-1  bg-white shadow-lg items-center border rounded-full mx-2 '>
          <div className='flex gap-3 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
          </svg>
            <input onChange={(e) => handleChange(e.target.value)} type='search' placeholder='Restaurant name or a dish...' className='w-64 outline-none my-2' /></div>
          <div> <img src={search} /></div>
        </div>
      </div>
      <List
        className={(
          "!z-50 p-5  !fixed shadow !rounded",
          searchingApi !== 0 ? "block" : "hidden"
        )}
      >
        {searchingApi?.map((searchingData) => {
          // const style = {
          //   backgroundColor: suggestion.active
          //     ? "#ebebeb"
          //     : "#fff",
          // };
          return (
            <MenuItem
              key={searchingData}
              onClick={()=>Navigate(`/items/${searchingData.restaurant_id}`)}
            >
              {searchingData.restaurant_name}
            </MenuItem>
          );
        })}
      </List>
    </>
  );
};

export default Header;
