import React, { useState,useEffect } from "react";
import { useNavigate, NavLink,useLocation } from "react-router-dom";
import qs from 'qs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userRegistrationApi, verifyOtpApi,loginSendOtpApi,resendOtpApi } from '../../utils/constant';


function Registration() {
  const [openOtp, setOpenOtp] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(true);
  const [openLogin, setOpenLogin] = useState(false);

  const navigate = useNavigate();
  // Sign Up UseState

  const [email, setEmail] = useState("");
  const [emailValidError, setEmailValidError] = useState(false);
  const [emailIsRequiredError, setEmailIsRequiredlError] = useState(false);

  const [name, setName] = useState("");
  const [nameValidError, setnameValidError] = useState(false);
  const [nameIsRequiredError, setnameIsRequiredlError] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phonelIsRequiredError, setPhoneIsRequiredlError] = useState(false);

  const [mobileOTP, setmobileOTP] = useState("");
  const [otpValidError, setotpValidError] = useState(false);

  const [signCheck, setSignCheck] = useState(false);
  const notify = () => toast("Wow so easy!");
  const items = useLocation();

  //  handle Email 
  const handleEmail = (value) => {
    const rex = /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,12}[.]{1}[A-Za-z.]{2,6}$/;
    if (value === "") {
      setEmailValidError(false);
    } else if (rex.test(value) === false) {
      setEmailValidError(true);
    } else {
      setEmailValidError(false);
    }
    setEmail(value);
  };

  // Handle Name
  const handleName = (value) => {
    const regex = /^[a-zA-Z0-9]+$/;

    if (value === "") {
      setnameValidError(false);
      setnameIsRequiredlError(true);
    } else if (regex.test(value) === false) {
      // setnameValidError(true);
      setnameIsRequiredlError(false);
      return;
    } else {
      setnameValidError(false);
      setnameIsRequiredlError(false);
    }
    setName(value);
  };
  //  handle phone number
  const handlePhone = (value) => {
    if (value.length > 10) {
      return;
    }
    if (/^\d{0,}?$/.test(value)) {
      setPhone(value);
    }
    const regex = /^[6-9]\d{9}$/;
    if (value === "") {
      setPhoneError(false);
      setPhoneIsRequiredlError(true);
    } else if (regex.test(value) === false) {
      setPhoneError(true);
      setPhoneIsRequiredlError(false);
    } else {
      setPhoneError(false);
      setPhoneIsRequiredlError(false);
    }
  };

  // Handle Mobile OTP
  const handleMobileOTP = (value) => {
    if (value.length > 10) {
      return;
    }
    if (/^\d{0,}?$/.test(value)) {
      setmobileOTP(value);
    }
    const pattern = /[0-9]/;
    if (pattern.test(value) === false) {
      setotpValidError(true);
    } else {
      setotpValidError(false);
    }
  };
  const handleTermsCond = (e) => {
    e.preventDefault();
    navigate('/termsConditions'
      // { state: { setSignUp } }
    );

  }
  useEffect(() => {
    if(items?.state == 'update'){
      console.log('***************');
      setOpenOtp(true)
      setOpenLogin(false)
      setOpenRegistration(false)
    }
    
}, [items])
  const verifyOtpApi_f = () => {
    if (mobileOTP?.length != 5) {
      toast.error("Please enter correct OTP.", { toastId: "2" });
      return
    }
    verifyOtpApi(qs.stringify({ mobileNumber: phone, otp: mobileOTP }))
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
          setOpenOtp(false)
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data)
          );
          localStorage.setItem(
            "userTokenOfBrbun",
            JSON.stringify(response?.data?.userToken)
          );
          localStorage.setItem(
            "cust_id",
            JSON.stringify(response?.data?.cust_id)
          );
          navigate('/')
        }
        if (response?.data?.status == 0) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userRegistrationApi_f = () => {
    if (email != "") {
      const rex = /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,12}[.]{1}[A-Za-z.]{2,6}$/;
      if (rex.test(email) === false) {
        toast.error("Please enter correct email.", { toastId: "3" });
        setEmailValidError(true);
        return;
      }
    }
    if (name === "" || !name) {
      toast.error("Name is required.", { toastId: "4" });
      return;
    }
    if (name?.length <= 1) {
      toast.error("Please enter correct name.", { toastId: "2" });
      return
    }
    if (phone === "" || !phone) {
      toast.error("Mobile is required.", { toastId: "2" });
      return;
    }
    if (phone.length !== 10 || phoneError) {
      toast.error("Please enter correct mobile.", { toastId: "2" });
      return
    }
    if (signCheck === false) {
      toast.error("Please accept T&C and Privacy Policy.", { toastId: "1" });
      return;
    }
    if ((name !== "" && phone !== "" && phone.length === 10 && phonelIsRequiredError === false && phoneError === false)) {
      userRegistrationApi(qs.stringify({
        mobileNumber: phone,
        name: name,
        emailId: email,
      }))
        .then((response) => {
          if (response?.data?.status == 1) {
            toast.success(response?.data?.message, { toastId: "6" });
            setOpenOtp(true)
            setOpenRegistration(false)
          }
          if (response?.data?.status == 0 || response?.data?.status == 2) {
            toast.error(response?.data?.message, { toastId: "5" });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error, { toastId: "7" });
        });
    }
  };
  const loginSendOtpApi_f = () => {
    if (phone === "" || !phone) {
      toast.error("Mobile is required.", { toastId: "2" });
      return;
    }
    if (phone.length !== 10 || phoneError) {
      toast.error("Please enter correct mobile.", { toastId: "2" });
      return
    }
    if (( phone !== "" && phone.length === 10 && phonelIsRequiredError === false && phoneError === false)) {
      loginSendOtpApi(qs.stringify({
        mobileNumber: phone,
      }))
        .then((response) => {
          console.log(response);
          if (response?.data?.status == 1) {
            toast.success(response?.data?.messege, { toastId: "6" });
            setOpenOtp(true)
            setOpenLogin(false)
          }
          if (response?.data?.status == 0 || response?.data?.status == 2) {
            toast.error(response?.data?.messege, { toastId: "5" });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error, { toastId: "7" });
        });
    }
  };
  const resendOtpApi_f = () => {
    if (phone === "" || !phone) {
      toast.error("Mobile is required.", { toastId: "2" });
      return;
    }
    if (phone.length !== 10 || phoneError) {
      toast.error("Please enter correct mobile.", { toastId: "2" });
      return
    }
    if (( phone !== "" && phone.length === 10 && phonelIsRequiredError === false && phoneError === false)) {
      resendOtpApi(qs.stringify({
        mobileNumber: phone,
      }))
        .then((response) => {
          console.log(response);
          if (response?.data?.status == 1) {
            toast.success(response?.data?.messege, { toastId: "6" });
            setOpenOtp(true)
            setOpenLogin(false)
          }
          if (response?.data?.status == 0 || response?.data?.status == 2) {
            toast.error(response?.data?.messege, { toastId: "5" });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error, { toastId: "7" });
        });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => {navigate(-1);setmobileOTP('')}}>
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
        <p className='text-xl font-bold text-white'>Registration/Login</p>
      </div>
      {openRegistration &&
        <>
          <div>
            <div className='flex justify-center items-center p-7'>
            </div>
            <div className='flex flex-col gap-3 pl-7'>
              <div className='flex gap-3 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 font-extrabold">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
                <div><p className='font-semibold'>Name</p>
                  <input
                    maxLength="20"
                    name="name"
                    value={name}
                    onChange={(e) => handleName(e.target.value)}
                    type='text' placeholder='Name' className='outline-none p-1 border-b border-black' />
                </div></div>
              <div className='flex gap-3 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 font-extrabold">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
              </svg>
                <div><p className='font-semibold'>Mobile</p>
                  <input type='text'
                    maxLength="10"
                    name="phone"
                    value={phone}
                    onChange={(e) => handlePhone(e.target.value)}
                    placeholder='Mobile' className='outline-none p-1 border-b border-black' />
                </div></div>
              <div className='flex gap-3 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 font-extrabold">
                <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
              </svg>
                <div><p className='font-semibold'>Email</p>
                  <input type='text'
                    maxLength="40"
                    name="email"
                    value={email}
                    onChange={(e) => handleEmail(e.target.value)}
                    placeholder='Email' className='outline-none p-1 border-b border-black' />
                </div></div>
              <div className='flex gap-3 items-center'>
                <input
                  className="outline-none p-1 border-b border-black"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={(e) => setSignCheck(e.target.checked)}
                />
                <label className="form-check-label">
                  <p type='button' > I accept the <span className="text-red-800" onClick={(e) => { handleTermsCond(e); }}>termsConditions</span>  and I have read the <span className="text-red-800" onClick={(e) => { handleTermsCond(e); }}>privacyPolicy</span> </p>
                </label></div>
            </div>
          </div>
          <div className='flex justify-center items-center '>
            <button className='text-white text-base font-semibold p-2 m-6 bg-[#ca1e24] w-full mt-8' onClick={(e) => userRegistrationApi_f(e)} >Register</button>
          </div>
          <p className='flex justify-center items-center '> or</p>
          <p className='flex justify-center items-center '> You have already an account</p>
          <div className='flex justify-center items-center '>
            <button className='text-white text-base font-semibold p-2 m-6 bg-[#ca1e24] w-full mt-8' onClick={(e) => {setOpenLogin(true);setOpenRegistration(false);setPhone('')}} >Login</button>
          </div>
        </>
      }
      {openOtp &&
        <div>
          <div className="!flex !justify-end mt-20">
          </div>
          {/* <p className="!font-bold !text-2xl !text-center py-5">
            Signup
          </p> */}
          <p className=" !text-lg !text-center ">
            Enter OTP sent to your mobile {phone}
          </p>
          <div className="flex flex-col justify-center">
            <input type="text"
              maxLength="5"
              name="mobileOTP"
              value={mobileOTP}
              onChange={(e) => handleMobileOTP(e.target.value)}
              className=" border-2 mx-20 border-black w-1/2 !my-4 !p-2 !rounded-xl !font-bold" />
              <p className='flex justify-center items-center ' onClick={(e) => resendOtpApi_f(e)} >Resend OTP</p>
            <div className='flex justify-center items-center '>
              <button className='text-white text-base font-semibold p-2 m-6 bg-[#ca1e24] w-full mt-8' onClick={(e) => verifyOtpApi_f(e)}>Validate</button>
            </div>
          </div>
        </div>
      }
      {openLogin &&
        <>
          <div>
            <div className='flex justify-center items-center p-7'>
            </div>

            <div className='flex gap-3 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 font-extrabold">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
            </svg>
              <div><p className='font-semibold'>Mobile</p>
                <input type='text'
                  maxLength="10"
                  name="phone"
                  value={phone}
                  onChange={(e) => handlePhone(e.target.value)}
                  placeholder='Mobile' className='outline-none p-1 border-b border-black' />
              </div></div>
          </div>
          <div className='flex justify-center items-center '>
            <button className='text-white text-base font-semibold p-2 m-6 bg-[#ca1e24] w-full mt-8' onClick={(e) => loginSendOtpApi_f(e)} >Login</button>
          </div>
        </>
      }

    </>
  );
}

export default Registration;

