import React from 'react'
import apple from "../../Assets/icon-AppStore_lg30tv.webp";
import android from "../../Assets/icon-GooglePlay_1_zixjxl.webp";
import home from "../../Assets/icons8-home-24.png";
import order from "../../Assets/icons8-wallet-30.png";
import profile from "../../Assets/icons8-person-24.png";
import { useNavigate } from 'react-router-dom';
import { Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Footer = () => {

  const Navigate = useNavigate();
  const cart = () => {
    Navigate("/checkout");
  };

  const order = () => {
    Navigate("/myorders")
  }

  const profile = () => {
    Navigate("/profile")
  };

  return (
    <>

      <div className='flex justify-around font-semibold p-2 sticky bottom-0 z-50 bg-white text-base text-gray-500 border-t'>
        <div className='flex flex-col items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-[#ca1e24]">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
          <p onClick={()=>Navigate("/")}>Food</p></div>
        <div className='border-r'></div>
        <div className='flex flex-col items-center' onClick={order}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-[#ca1e24]">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
        </svg>
          <p>Orders</p></div>
        <div className='border-r'></div>

        <div className='flex flex-col items-center' onClick={cart}>
          <Badge  color="primary" >
            <ShoppingCartIcon className='text-[#ca1e24]' />
          </Badge>
          <p>Cart</p></div>
        <div className='border-r'></div>

        <div className='flex flex-col items-center' onClick={profile}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-[#ca1e24]">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
          <p>Profile</p></div>
      </div>
    </>
  )
}

// footer will not be there in app because there is no option to add in footer

export default Footer;