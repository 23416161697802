import { useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import qs from 'qs';
import abc from "../../Assets/icons8-location-30.png";
import bcd from "../../Assets/ef3i0k1ilhdsistcykog.webp";
import veg from "../../Assets/icons8-vegetarian-food-symbol-48.png";
import offer from "../../Assets/icons8-discount-24.png";
import { cartGetApi,cartUpdateApi, cartClearApi, saveOrderDetails_newApi, user, deliveryChargeApi, logosImage,saveOrderDetailsApi } from '../../utils/constant';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ToastContainer, toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
const Checkout = () => {
    const [cartItems, setCartItems] = useState([]);
    const [carttotal, setCarttotal] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState([]);
    const [deliveryCharge, setDeliveryCharge] = useState([]);
    const [extraSms, setExtraSms] = useState();
    const userAddress = JSON.parse(localStorage.getItem("userAddress"));
    const userAddressLatLong = JSON.parse(localStorage.getItem("userAddressLatLong"));
    const items = useLocation();
    const Navigate = useNavigate()

    const Payment = () => {
        Navigate("/payment")
    }

    const Address = () => {
        Navigate("/addaddress")
    }

    const Getcart_f = () => {
        cartGetApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
            .then((response) => {
                setCartItems(response?.data.itemList)
                setCarttotal(response?.data)
                deliveryChargeApi_f(response?.data?.restaurant_id)
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const deliveryChargeApi_f = (rId) => {
        deliveryChargeApi(qs.stringify({
            userlat: userAddressLatLong?.lat,
            userlng: userAddressLatLong?.lng,
            custId: user?.cust_id,
            restaurantId: rId
        }))
            .then((response) => {
                setDeliveryCharge(response?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // addToCartApi api calling
    const saveOrderApi_f = (e, type, finalPrice, restaurantId, restaurantName, delivery_charge, distance,razorpay_payment_id,paymentStatus) => {
        if (!selectedAddress) {
            toast.error("Please select delivery address.", { toastId: "2" });
            return
        }
        if (!cartItems) {
            toast.error("Please select your food.", { toastId: "3" });
            return
        }
        let selected_product = []
        cartItems?.map((items) => (
            selected_product.push({ food_menu_id: items?.food_menu_id, food_menu_name: items?.food_menu_name, qty: items?.qty, restaurent_id: restaurantId, price: items?.totalPrice })
        ))
        // let deliveryAddress = selectedAddress?.address1 selectedAddress?.address2, selectedAddress?.city, selectedAddress?.postcode
        const requestData = {
            "customerContactNo": user?.mob_no,
            "customerId": user?.cust_id,
            "customerName": user?.user_name,
            "deliveryAddress": selectedAddress?.address2,
            "deliveryCharge": delivery_charge,
            "extraMessage": extraSms,
            // "foodName": "Cheese Pakoda(NA)-1",
            "paymentType": type,
            "restaurantId": restaurantId,
            "restaurantName": restaurantName,
            "totalAmount": finalPrice,
            "totalDistance": distance,
            "paymentStatus": paymentStatus,
            "transactionId": razorpay_payment_id,
            // "totalAdminPrice": "19",
            "selected_product": selected_product
        }
        saveOrderDetails_newApi(requestData)
            .then((response) => {
                toast.success(response?.data?.message, { toastId: "5" });
                saveOrderDetailsApi_f(e, type, finalPrice, restaurantId, restaurantName, delivery_charge, distance,razorpay_payment_id,paymentStatus)
                // saveOrderApi(response?.data?.menuCategoryList)
            })
            .catch((error) => {
                console.log(error);
            });
    };
    // saveOrderDetailsApi api calling
    const saveOrderDetailsApi_f = (e, type, finalPrice, restaurantId, restaurantName, delivery_charge, distance,razorpay_payment_id,paymentStatus) => {
        if (!selectedAddress) {
            toast.error("Please select delivery address.", { toastId: "2" });
            return
        }
        if (!cartItems) {
            toast.error("Please select your food.", { toastId: "3" });
            return
        }
        let selected_product = []
        cartItems?.map((items) => (
            selected_product.push({ food_menu_id: items?.food_menu_id, food_menu_name: items?.food_menu_name, qty: items?.qty, restaurent_id: restaurantId, price: items?.totalPrice })
        ))
        // let deliveryAddress = selectedAddress?.address1 selectedAddress?.address2, selectedAddress?.city, selectedAddress?.postcode
        const requestData = {
            "selfPickup": 'NO',
            "customerId": user?.cust_id,
            "customerName": user?.user_name,
            "latitude":  userAddressLatLong?.lat,
            "longitude": userAddressLatLong?.lng,
            "coupon_code": 'NA',
            "coupon_amount": 0,
            "deliveryCharge": delivery_charge,
            "extraMessage": extraSms,
            // "foodName": "Cheese Pakoda(NA)-1",
            "paymentType": type,
            "totalDistance": distance,
            "paymentStatus": paymentStatus,
            "transactionId": razorpay_payment_id,
        }
        saveOrderDetailsApi(requestData)
            .then((response) => {
                // toast.success(response?.data?.message, { toastId: "5" });
                Navigate('/myorders')
                // saveOrderApi(response?.data?.menuCategoryList)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        setSelectedAddress(items?.state?.add)
        Getcart_f();
    }, [items])

    const Item = (id) => {
        Navigate(`/items/${id}`)
    }
    const selectAddress = (id) => {
        Navigate(`/Savedaddress/checkout`)
    }
    var delivery_charge
    if (carttotal?.grandTotalPrice > deliveryCharge?.maxOrderAmount) {
        delivery_charge = deliveryCharge?.maxDeliveryCharge
    } else {
        delivery_charge = deliveryCharge?.minDeliveryCharge
    }
   
    const handlePayment = async (e, type, finalPrice, restaurantId, restaurantName, delivery_charge, distance) => {
        if (!selectedAddress) {
            toast.error("Please select delivery address.", { toastId: "2" });
            return
        }
        if (!cartItems) {
            toast.error("Please select your food.", { toastId: "3" });
            return
        }
        const options = {
            key: "rzp_live_0AjnIg0NSI1UYQ",
            amount: 1 * 100,
            currency: "INR",
            name: carttotal?.restaurant_name,
            description: "Thank you for your order",
            image: logosImage,
            //   order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                // paymentGetway_f(response.razorpay_payment_id);
                console.log(response);
                let paymentStatus = 'PENDING'
                if(response?.razorpay_payment_id){
                    paymentStatus = 'SUCCESS'
                }
                saveOrderApi_f(e, type, finalPrice, restaurantId, restaurantName, delivery_charge, distance,response.razorpay_payment_id,paymentStatus)
            },
            prefill: {
                name: user?.user_name,
                email: user?.email,
                contact: user?.mob_no,
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };


     // update cart
  const cartUpdateApi_f = (cartQty, cartId) => { 
    cartUpdateApi(
      qs.stringify({
        custId: user?.cust_id,
        coupon_id: "None",
        qty: cartQty,
        cartId: cartId,
      })
    )
      .then((response) => {
        Getcart_f();
       
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // clear cart if one product
  const cartClearApi_f = (cartId) => {
 cartClearApi(
      qs.stringify({ custId: user?.cust_id, coupon_id: "None", cartId: cartId })
    )
      .then((response) => {
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Quantity Change By User

  let handleIncrement = (cartId , cartQty) => {
    let qt = parseInt(cartQty) + 1;
    
    if (qt == 0) {
      cartClearApi_f(cartId);
    } else {
      cartUpdateApi_f(qt, cartId);
    }
  };
  let handleDecrement = ( cartId, cartQty ) => {
    let qt = parseInt(cartQty) - 1;
    if (qt == 0) {
      cartClearApi_f(cartId);
    } else {
      cartUpdateApi_f(qt, cartId);
    }
  };

    return (
        <>
            <ToastContainer />
            <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => Navigate(-1)}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                </svg>
                <p className='text-xl font-bold text-white'>My Cart</p>

            </div>

            <div className=' py-1 lg:flex-row flex flex-col-reverse gap-5'>
                <div className='lg:w-2/3 w-full'>
                    <div className='bg-white'>
                        <div className='p-6'>
                            <div className='p-3'>
                                <button className='text-xl font-semibold text-red-800'>Select delivery address</button>
                                {/* <p className='font-medium text-gray-400'>You have a saved address in this location</p> */}
                            </div>
                            <div className='lg:flex-row flex flex-col gap-5'>
                                <div className='flex gap-4 p-3 border border-gray-300 lg:w-1/2 w-full shadow-lg'>
                                    <div className='pt-3'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                    </div>
                                    <div>
                                        {/* onClick={Payment} */}
                                        <button className='text-white font-semibold px-4 py-2 bg-[#60b246] rounded my-2' onClick={(e) => { selectAddress() }}>DELIVER TO </button>
                                        <p className='text-base font-semibold'>Others</p>
                                        <p className='text-sm font-light text-gray-400 pb-5'>{selectedAddress?.address1}, {selectedAddress?.address2},<br /> {selectedAddress?.city} {selectedAddress?.postcode}</p>
                                        
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>

                <div className='lg:w-1/3 w-full bg-white px-5 mb-7 mt-3 overflow-scroll'>
                    <div className='flex gap-4 py-1 pb-2 bg-white'>
                        {/* <div><img src={bcd} className='w-14' /></div> */}
                        <div className='border-b border-black'><p className='text-xl font-semibold'>{carttotal?.restaurant_name}</p>
                            {/* <p className='text-sm text-gray-500'>Hazratganj</p> */}
                        </div>
                    </div>
                    <div className='flex gap-2 items-center py-3 justify-around'>
                        <p className='text-xs text-gray-500'>Your Order<br /></p>
                        <button onClick={(e) => { Item(carttotal?.restaurant_id) }} className='text-sm text-red-800' >Add More Items</button>
                    </div>
                    {cartItems &&
                        cartItems?.map((items) => (
                            <div className='flex gap-2 items-center py-3 justify-around'>
                                {/* <img src={veg} className='w-5 h-5' /> */}
                                <p className='text-xs text-gray-500'> {items?.qty} X {items?.food_menu_name}<br /> <span className='text-red-800'>
                        ({items?.variationList[0]?.extra_item_name})</span></p>
                        <p className='text-sm'>Rs. {items?.totalPrice}</p>
            <div className=' rounded'>
                  <span
                      className='border  px-1'
                     
                      onClick={() => handleDecrement(items?.cart_id,items?.qty)}
                    >
                      -
                    </span>
                    <span className='border  px-1'>{items.qty}</span>
                    
                    <span
                      className='border  px-1'
                      onClick={() => handleIncrement(items?.cart_id, items?.qty)}
                    >
                      +
                    </span>
                  </div>
                  {/* <p>Qty:{items?.qty}</p> */}
                  <Delete className=""
                    onClick={() => cartClearApi_f(items?.cart_id)}
                      />
                                {/* <div className='flex gap-2 border px-1 text-sm'><button>-</button><button>{items?.qty}</button><button>+</button></div> */}
                               
                            </div>
                        ))}
                         <div className='text-sm font-semibold py-2'><p>PAYMENT INFO</p></div>
                    <div className='flex justify-between text-sm text-gray-500 py-2'>
                        <p>Item Total</p>  <p>Rs.{carttotal?.grandTotalPrice}</p>
                    </div>
                    <div className='flex justify-between text-sm text-gray-500 py-2'>
                        <p>Delivery Fee</p>  <p>Rs. {carttotal?.grandTotalPrice ? carttotal?.grandTotalPrice > deliveryCharge?.maxOrderAmount ? deliveryCharge?.maxDeliveryCharge : deliveryCharge?.minDeliveryCharge : 0}</p>
                    </div>
                    <div className='border-dashed border-b my-2 border-gray-300'></div>
           <div className='border border-b my-3 border-gray-500'></div>
                    <div className='flex justify-between font-semibold py-2 bg-white'>
                        <p>To Pay</p>  <p>Rs. {carttotal?.grandTotalPrice ? parseInt(carttotal?.grandTotalPrice) + parseInt(delivery_charge) : 0}</p>
                    </div>
                    <div className='border border-b mt-3 border-gray-500'></div>
                </div>
            </div>
            <div className='flex justify-around font-semibold p-2 sticky bottom-0 z-50 bg-[#ca1e24] text-base text-gray-500 border-t'>
                <button className='text-white  px-4 py-1  rounded my-2' onClick={(e) => {
                    saveOrderApi_f(e, 'COD', parseInt(carttotal?.grandTotalPrice), carttotal?.restaurant_id
                        , carttotal?.restaurant_name, delivery_charge, deliveryCharge?.distance,'NA','NA')
                }}>COD</button>
                <div className='border-r'></div>
                <button className='text-white  px-4 py-1  rounded my-2' onClick={(e) => {
                    handlePayment(e, 'ONLINE', parseInt(carttotal?.grandTotalPrice) + parseInt(delivery_charge), carttotal?.restaurant_id
                        , carttotal?.restaurant_name, delivery_charge, deliveryCharge?.distance)
                }}>PAY ONLINE </button>
            </div>
        </>
    )
}

export default Checkout;