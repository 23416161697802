import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Pages/Home';
import Layout from './Layout';
import ItemsPage from './Pages/Items';
import Checkout from './Pages/Checkout';
import PaymentPage from './Pages/payment';
import AddAddress from './Pages/AddAddress';
import Help from './Pages/Help';
import ProfilePage from './Pages/ProfilePage';
import SavedAddresses from './Pages/SavedAddresses';
import AddFeedback from './Pages/AddFeedback';
import MyOrders from './Pages/Orders';
import ViewAll from './Pages/ViewAll';
import SearchPage from './Pages/SearchPage';
import Profilemainpage from './Pages/Profilemainpage';
import CategoryWiseRestaurant from './Pages/CategoryWiseRestaurant';
import Registration from './Pages/Registration';
import PrivateRoutes from "./hooks/authenticationPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectAddress from './Pages/SelectAddress';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Registration />} />
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Layout component={<HomePage />} />} />
            <Route path='/items/:id' element={<ItemsPage />} />
            <Route path='/catWiseRestaurant/:id' element={<CategoryWiseRestaurant />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/payment' element={<PaymentPage />} />
            <Route path='/addaddress' element={<AddAddress />} />
            <Route path='/addaddress/:type' element={<AddAddress />} />
            <Route path='/help' element={<Help />} />
            <Route path='/profile' element={<ProfilePage />} />
            <Route path='/savedaddress/:type' element={<SavedAddresses />} />
            <Route path='/addfeedback' element={<AddFeedback />} />
            <Route path='/myorders' element={<MyOrders />} />
            <Route path='/all/:type' element={<ViewAll />} />
            <Route path='/search' element={<SearchPage />} />
            <Route path='/profiles' element={<Profilemainpage />} />
            <Route path='profile/login' element={<Registration />} />
            <Route path='selectAddress' element={<SelectAddress />} />
            {/* <Route path='/registration' element={<Registration />} /> */}
          </Route>
        </Routes>
      </BrowserRouter >
    </>
  );
};

//responsive-- Home page, items page, checkout page, payment page, help page, profile page ,
//responsive-- saved address page, add feedback page..

// add address page responsive issue in google map


//when click on cart it should redirect to checkout page means checkout page is cart page....
// there should be 3 pages checkout page, add address page, payment page

//when click on profile myorders details also will open with profile details

export default App;