import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import upi from "../../Assets/icons8-bhim-upi-50.png";
import card from "../../Assets/icons8-debit-card-50.png";
import wallet from "../../Assets/icons8-wallet-30.png";
import banking from "../../Assets/icons8-banking-24.png";
import { useNavigate } from "react-router-dom";
import { insert_feedbackApi, user } from '../../utils/constant';
import qs from 'qs';
import { Textarea } from 'flowbite-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddFeedback = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [feed, setfeed] = useState("");


  const feed_f = () => {
    if(!feed){
      toast.error("Please enter some feedback ", { toastId: "7" });
      return;
    }
    insert_feedbackApi(qs.stringify({ custId: user?.cust_id, custName: user?.user_name, customerContactNumber: user?.mob_no, feedback: feed }))
      .then((response) => {
        toast.success(response.data.message, { toastId: "7" });
        navigate(-1)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
  <ToastContainer />
      <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => navigate(-1)}>
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
        <p className='text-xl font-bold text-white'>Add Feedback</p>
      </div>

      <div className='flex flex-col justify-center items-center lg:pl-52 pl-0 pt-10'>

        <div className='text-gray-500 font-bold'>
          <p>Please Give Your Feedback Here 👇</p>
        </div>
        <div>
          <textarea type='text'
            onChange={(e) => setfeed(e.target.value)}
            placeholder='Give your Feedback here' className='m-5 outline-none w-80 h-20 border rounded-md p-5' />
        </div>
        <div>
          <button type='submit' onClick={feed_f} className='px-3 p-1 bg-green-400 text-white hover:shadow-md text-lg font-semibold rounded-md'>Submit</button>
        </div>

      </div>


    </>
  )
}

export default AddFeedback;