import item1 from "../../Assets/e437cba7f2def94d3e067ba1a3e592bc.webp";
import { orderHistoryApi, user, logosImage, restaurantImage } from '../../utils/constant';
import React, { useEffect, useState } from "react";
import { useNavigate, NavLink, useParams } from 'react-router-dom';
import qs from 'qs';


const MyOrders = () => {
    const navigate = useNavigate()
    const id = useParams('id');
    const [detail, setDetail] = useState([]);


    const OrderDetail_f = () => {
        orderHistoryApi(qs.stringify({
            custId: user?.cust_id
        }))
            .then((response) => {
                setDetail(response.data.orderHistoryList);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        OrderDetail_f();
    }, [])

    return (
        <>

            <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => navigate(-1)}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                </svg>
                <p className='text-xl font-bold text-white'>Order History</p>
            </div>

            <div className='lg:px-20 px-1 lg:py-10 pt-1 lg:flex-row flex flex-col lg:gap-16 gap-2'>
                <div className='grid lg:grid-cols-3 grid-cols-1 gap-3 p-3'>
                    {detail?.map((item) => {
                        return <>
                            <div className='p-2 border h-fit rounded-md w-full hover:shadow-lg bg-gray-50'>
                                <div className='flex justify-between'>
                                    <div className='flex gap-2'>
                                        <img src={item?.restaurant_image != "" && item?.restaurant_image != "NA" ? `${restaurantImage}${item?.restaurant_image}` : logosImage} className='w-12 h-12 rounded-md' />
                                        <div className='flex flex-col'><p className='text-lg font-semibold'>{item.restaurant_name}</p><p className='text-gray-400 text-xs'></p></div>
                                    </div>
                                    <div>
                                        <button>{item?.restaurant_order_status}</button>
                                    </div>
                                </div>

                                <div className='border-b border-gray-300 py-1'></div>

                                <div className='p-4 grid grid-cols-2'>
                                    <div><p className='font-semibold text-sm'>ORDER NUMBER</p>
                                        <p className='text-xs pb-2 text-gray-600'>{item?.order_id}</p></div>
                                    <div><p className='font-semibold text-sm'>TOTAL AMOUNT</p>
                                        <p className='text-xs pb-2 text-gray-600'>{item?.total_amount}</p></div>
                                    <div><p className='font-semibold text-sm'>ITEMS</p>
                                        <p className='text-xs pb-2 text-gray-600'> {item.food_name}</p></div>

                                </div>
                            </div>
                        </>
                    })}

                </div>
            </div>
        </>
    )
}

export default MyOrders