import React from 'react'
import { useNavigate } from 'react-router-dom'
import logos from "../../Assets/logos.png";

const Profilemainpage = () => {
  const navigate = useNavigate();

  const Update = () => {
    navigate("/profiles");
  };

  return (
    <>
      <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => navigate(-1)}>
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
        <p className='text-xl font-bold text-white'>Profile</p>
      </div>

      <div className='flex flex-col justify-center items-center p-6'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-24 h-24 shadow-lg rounded-full text-gray-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <div className='bg-gray-100 flex flex-col items-center mt-10 p-5 rounded-lg'>
          <p className='text-2xl font-medium text-[#ca1e24] p-2'>Saurabh Pandey</p>
          <p className='text-xl text-gray-500 p-2'>8647634644</p>
          <p className='text-xl text-gray-500'>saurabhp2311@gmail.com</p>
        </div>
        <p className='text-lg bg-violet-300 p-2 mt-10 px-8 text-white font-semibold rounded-md' onClick={Update}>Update</p>

      </div>
    </>
  )
}

export default Profilemainpage;