import { useNavigate, NavLink, useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import qs from 'qs';
import heart from "../../Assets/icons8-heart-50.png";
import search from "../../Assets/icons8-search-24.png";
import star from "../../Assets/icons8-star-filled-50 (1).png"
import cycle from "../../Assets/icons8-cycling-mountain-bike-48.png";
import rupee from "../../Assets/icons8-rupee-64.png";
import time from "../../Assets/icons8-time-50.png";
import veg from "../../Assets/icons8-vegetarian-food-symbol-48.png";
import {
  restaurantDetailsApi, getCategoryWiseMenuApi, menuCategoryApi,
  cartAddApi, FoodMenuImage, logosImage, user, getExtraItemApi, cartGetApi, cartUpdateApi, cartClearApi
} from '../../utils/constant';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge } from '@mui/material';
import { Divider } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CloseOutlined, Person } from "@mui/icons-material";
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  borderRadius: 0,
  boxShadow: 24,
  p: 4,
}




const ItemsPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen();
  const id = useParams('id');
  const [restaurantDetails, setRestaurantDetails] = useState([]);
  const [categoryWiseMenu, setCategoryWiseMenuApi] = useState([]);
  const [menuCategory, setCenuCategoryApi] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [carttotal, setCarttotal] = useState([]);
  const [getExtraItem, setExtraItemApi] = useState([]);
  const userAddressLatLong = JSON.parse(localStorage.getItem("userAddressLatLong"));


  // restaurantDetails api calling
  const restaurantDetails_f = () => {
    restaurantDetailsApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, restaurantId: id?.id }))
      .then((response) => {
        setRestaurantDetails(response?.data?.restaurantDetails)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // categoryWiseMenuApi api calling
  const categoryWiseMenuApi_f = (category) => {
    getCategoryWiseMenuApi(qs.stringify({ categoryName: category, restaurantId: id?.id }))
      .then((response) => {
        setCategoryWiseMenuApi(response?.data?.menuList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // menuCategoryApi api calling
  const menuCategoryApi_f = () => {
    menuCategoryApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, restaurantId: id?.id }))
      .then((response) => {
        setCenuCategoryApi(response?.data?.menuCategoryList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Getcart_f = () => {
    cartGetApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        setCartItems(response?.data?.itemList)
        setCarttotal(response?.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // addToCartApi api calling
  const addToCartApi_f = (
    i,
    qty1,
    cPrice,
    foodMenuId,
    foodMenuName,
    sAdminPrice,
    MListid,
    restaurantId,
    userId,
    Variants,
    Variations,
    qtyTot,
    adminItemPrice
  ) => {
    Variations !== 'NoVariants' ?

      cartAddApi({
        foodMenuId: foodMenuId,
        qty: qty1,
        menuExtraItemId: [
          {
            extra_customer_price: cPrice,
            extra_item_name: foodMenuName,
            extra_super_admin_price: sAdminPrice,
            id: i,
            is_checked: "0",
          },
        ],
        menuExtraItemType: "",
        totalPrice: cPrice,
        custId: user?.cust_id ? user?.cust_id : userId,
        restaurantId: restaurantId,
        variationItemType: 1,
        variationExtraItemId: [
          {
            extra_customer_price: cPrice,
            extra_item_name: foodMenuName,
            extra_super_admin_price: sAdminPrice,
            id: i,
          }
        ],
      }) 
      .then((response) => {
        console.log("+++++++++++++++", response?.data?.message);
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
          // categoryWiseMenuApi_f("All")
          handleClose()
          Getcart_f();

        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
        handleClose()
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      })
:
      cartAddApi({
        foodMenuId: foodMenuId,
        qty: qty1,
        menuExtraItemId: [
          {
            extra_customer_price: cPrice,
            extra_item_name: foodMenuName,
            extra_super_admin_price: sAdminPrice,
            id: i,
            is_checked: "0",
          },
        ],
        menuExtraItemType: "",
        totalPrice: cPrice,
        custId: user?.cust_id ? user?.cust_id : userId,
        restaurantId: restaurantId,
        variationItemType: "",
        itemPrice: cPrice,
        adminItemPrice: adminItemPrice,
        variationExtraItemId: [],
      })

        .then((response) => {
          console.log("+++++++++++++++", response?.data?.message);
          if (response?.data?.status == 1) {
            toast.success(response?.data?.message, { toastId: "6" });
            // categoryWiseMenuApi_f("All")
            Getcart_f();

          }
          if (response?.data?.status == 0 || response?.data?.status == 2) {
            toast.error(response?.data?.message, { toastId: "5" });
          }
          handleClose()
          Getcart_f();
        })
        .catch((error) => {
          console.log(error);
        });
  };

  useEffect(() => {
    restaurantDetails_f();
    menuCategoryApi_f();
    categoryWiseMenuApi_f("All");
    Getcart_f();
  }, []);

  const Item = (category) => {
    categoryWiseMenuApi_f(category);
  };
  const addCart_f = (
    i,
    qty1,
    cPrice,
    foodMenuId,
    foodMenuName,
    sAdminPrice,
    MListid,
    restaurantId,
    Variations,
    priceTota,
    qtyTota,
    adminItemPrice
  ) => {
    console.log(Variations, "Variations");
    var index = cart.findIndex((x) => x.index == i);
    if (index === -1) {
      setCart((oldArray) => [
        ...oldArray,
        { index: i, quantity: qty1, price: parseInt(cPrice) },
      ]);
    } else {
      const newState = cart.map((obj) => {
        if (obj.index === i) {
          return { ...obj, quantity: qty1, price: parseInt(cPrice) };
        }
        // 👇️ otherwise return the object as is
        return obj;
      });
      setCart(newState);
    }

    addToCartApi_f(
      i,
      qty1,
      cPrice,
      foodMenuId,
      foodMenuName,
      sAdminPrice,
      MListid,
      restaurantId,
      user?.cust_id,
      adminItemPrice,
      Variations,
    );
  };
  // update cart
  const cartUpdateApi_f = (cartQty, cartId) => {
    cartUpdateApi(
      qs.stringify({
        custId: user?.cust_id,
        coupon_id: "None",
        qty: cartQty,
        cartId: cartId,
      })
    )
      .then((response) => {
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // clear cart if one product
  const cartClearApi_f = (cartId) => {
    cartClearApi(
      qs.stringify({ custId: user?.cust_id, coupon_id: "None", cartId: cartId })
    )
      .then((response) => {
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Quantity Change By User

  let handleIncrement = (cart_id, customer_price, cartQty, cartId) => {
    let qt = parseInt(cartQty) + 1;
    if (qt == 0) {
      cartClearApi_f(cartId);
    } else {
      cartUpdateApi_f(qt, cartId);
    }
  };
  let handleDecrement = (cart_id, customer_price, cartQty, cartId) => {
    let qt = parseInt(cartQty) - 1;
    if (qt == 0) {
      cartClearApi_f(cartId);
    } else {
      cartUpdateApi_f(qt, cartId);
    }
  };



  const viewCart_f = (totalqt, totalpr) => {
    navigate("/checkout");
  };

  console.log(carttotal, 'categoryWiseMenu')

  // categoryWiseMenuApi api calling
  const getExtraItemApi_f = (e, foodMenuId) => {
    e.preventDefault()
    setOpen(true)

    getExtraItemApi(
      qs.stringify({ restaurantId: id?.id, foodMenuId: foodMenuId })
    )
      .then((response) => {
        setExtraItemApi(response.data.variationList)
        Getcart_f()
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(categoryWiseMenu, "fffff")



  return (
    <>
      <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => navigate(-1)}>
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
        <Badge color="primary">
          <ShoppingCartIcon color="action" />
        </Badge>
        <p className='text-xl font-bold text-white'>Items: {carttotal?.grandTotalQty} | {carttotal?.grandTotalPrice} </p>
        <button className='p-2 px-6 bg-[#ffffff] text-[#60b246] text-sm font-bold rounded-md shadow  right-5 top-[75px]' onClick={(e) => { viewCart_f() }}><p
        >View Cart</p></button>
      </div>

      <div className='lg:px-64 px-2'>

        <div className='px-1 flex justify-between items-center'>
          <div>
            <p className='text-lg font-bold text-gray-600 py-1'>{restaurantDetails?.restaurant_name}</p>
            <p className='text-sm text-gray-500 '>{restaurantDetails?.food_category_name}</p>
            <p className='text-sm text-gray-500'>{restaurantDetails?.restaurant_type} </p>
          </div>
          <div className='flex flex-col justify-center items-center border border-gray-200 rounded-md p-1 mt-4 mb-8'>
            <div className='flex gap-1 border-b border-gray-200 pb-1'>
              <img src={star} className='h-4' /><p className='text-sm font-extrabold text-green-500'>{restaurantDetails?.avgreview}</p>
            </div>
            <div className='text-xs text-gray-400 pt-1 flex items-center justify-center mx-2'><p>{restaurantDetails?.totalreview} Ratings</p></div>
          </div>
        </div>
        <div className='flex py-2'>
          <div className='flex gap-2 border-r border-gray-500 border-h-[4px] pr-2 text-sm text-gray-500 justify-center items-center'><p>{restaurantDetails?.restaurant_address}</p></div>
          <div className='text-sm text-gray-500 px-2 flex justify-center items-center'><img src={cycle} className='h-5' /><p> {restaurantDetails?.distance}KM</p></div>
        </div>

        <div className='border-b border-gray-300  border-dashed mx-2 py-1 '></div>
        <div className='flex gap-7 text-base overflow-scroll font-bold text-gray-600 p-2 '>
          {menuCategory &&
            menuCategory?.map((menuCate) => (
              <button className='flex justify-center items-center' onClick={(e) => { Item(menuCate?.food_menu_category) }}><p>{menuCate?.food_menu_category}</p></button>
            ))}
        </div>

        <div className='border-b border-gray-300 mx-2  '></div>


        {categoryWiseMenu &&
          categoryWiseMenu?.map((menuCate, i) => (
            <>
              <div className="flex justify-between items-center px-2 pb-3">
                <div>
                  <img src={veg} className="h-5 mt-2" />
                  <p className="text-lg turncate font-semibold text-gray-600 pt-1">
                    {menuCate?.food_menu_name}{" "}
                    {menuCate?.food_quantity != "NA"
                      ? `[${menuCate?.food_quantity}]`
                      : ""}
                  </p>

                  {

                    categoryWiseMenu[0]?.variation === "0" ?

                      <p className="text-base text-gray-500 pt-1">
                        ₹ {menuCate?.customer_price}
                      </p>
                      : <p className="text-base font-bold text-red-800 pt-1"
                        onClick={(e) => {

                          getExtraItemApi_f(
                            e,

                            menuCate?.food_menu_id,
                            menuCate?.restaurant_id
                          );
                        }}>
                        Customizable
                      </p>
                  }
                </div>
                <div className="relative">
                  <img
                    src={
                      menuCate?.food_menu_image != "" &&
                        menuCate?.food_menu_image != "NA"
                        ? `${FoodMenuImage}${menuCate?.food_menu_image}`
                        : logosImage
                    }
                    className="w-28 h-28 rounded-md"
                  />

                  {carttotal?.itemList?.map((i) => i.food_menu_id).includes(menuCate?.food_menu_id) ? (
                    <span className="flex font-bold justify-center ">
                      <span className="border rounded-sm flex p-1">
                        <span
                          className="border-r px-3 lg:cursor-pointer"
                          onClick={() =>
                            handleDecrement(
                              i,
                              menuCate?.customer_price,
                              carttotal?.itemList?.find((i) => i.food_menu_id === menuCate?.food_menu_id).qty,
                              carttotal?.itemList?.find((i) => i.food_menu_id === menuCate?.food_menu_id).cart_id
                            )
                          }
                        >
                          -
                        </span>
                        <p className="px-3">{carttotal?.itemList?.find((i) => i.food_menu_id === menuCate?.food_menu_id).qty}</p>
                        <span
                          className="border-l px-3 lg:cursor-pointer"
                          onClick={() =>
                            handleIncrement(
                              i,
                              menuCate?.customer_price,
                              carttotal?.itemList?.find((i) => i.food_menu_id === menuCate?.food_menu_id).qty,
                              carttotal?.itemList?.find((i) => i.food_menu_id === menuCate?.food_menu_id).cart_id
                            )
                          }
                        >
                          +
                        </span>
                      </span>
                    </span>
                  ) :

                    (
                      categoryWiseMenu[0]?.variation === "0" ?
                        <button
                          className="p-2 px-6 bg-[#ffffff] text-[#60b246] text-sm font-bold rounded-md shadow absolute right-5 top-10"
                          onClick={(e) => {
                            localStorage.getItem("userTokenOfBrbun") ?
                              (addCart_f(
                                i,
                                1,
                                menuCate?.customer_price,
                                menuCate?.food_menu_id,
                                menuCate?.food_menu_name,
                                menuCate?.super_admin_price,
                                menuCate?.id,
                                menuCate?.restaurant_id,
                                "NoVariants"
                              )) : toast("! login required")
                          }
                          }
                        >
                          <p>ADD</p>
                        </button>
                        : null

                    )
                  }


                  {

                    categoryWiseMenu[0]?.variation === "1" ?

                      <button className="p-2 bg-[#ffffff] text-[#60b246] text-sm font-bold rounded-md shadow absolute left-5 px-5 top-10" onClick={(e) => {

                        getExtraItemApi_f(
                          e,

                          menuCate?.food_menu_id,
                          menuCate?.restaurant_id
                        );
                      }} >
                        <p className="text-center text-red-800">Add</p>
                      </button>
                      : null
                  }
                </div>

              </div>
              <div className="border-b border-gray-300 mx-2"></div>

              <Modal className=""
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                  <CloseOutlined className="!ml-20" onClick={() => handleClose(true)} />
                  <div className="my-4">
                    <img
                      src={
                        menuCate?.food_menu_image != "" &&
                          menuCate?.food_menu_image != "NA"
                          ? `${FoodMenuImage}${menuCate?.food_menu_image}`
                          : logosImage
                      }
                      className="h-32 rounded"
                    />
                    <p className="text-center my-2 mt-5">Customize the pizza </p>
                    <Divider />
                  </div>
                  {getExtraItem?.map((get) => {
                    return (
                      <div className="flex justify-between my-4" key={get.id}>
                        <div className="flex justify-start gap-5">
                          <input type="checkbox" className=" text-bold "
                            onClick={(e) => {
                              addCart_f(
                                get.id,
                                1,
                                get.extra_customer_price,
                                get?.food_menu_id,
                                get.extra_item_name,
                                menuCate?.super_admin_price,
                                menuCate?.customer_price,
                                menuCate?.restaurant_id,
                                i,
                                "Variations"

                              );
                            }} /><p className="mt-1 text-xs">{get.extra_item_name}</p></div>

                        <p className="text-red-800"> Rs:{get.extra_customer_price}</p>
                      </div>

                    )
                  })}

                </Box>
              </Modal>
            </>
          ))}
      </div>
    </>
  );
};

export default ItemsPage;