import React, { useState, useEffect } from "react";
import qs from 'qs';
import upi from "../../Assets/icons8-bhim-upi-50.png";
import card from "../../Assets/icons8-debit-card-50.png";
import wallet from "../../Assets/icons8-wallet-30.png";
import banking from "../../Assets/icons8-banking-24.png";
import item1 from "../../Assets/e437cba7f2def94d3e067ba1a3e592bc.webp";
import abc from "../../Assets/icons8-help-50.png";
import bcd from "../../Assets/icons8-person-24 (1).png";
import cde from "../../Assets/icons8-login-24.png";
import def from "../../Assets/icons8-email-sign-24.png";
import { useNavigate, useLocation } from 'react-router-dom';
import { updateProfileApi, updateProfileSendOtpApi } from '../../utils/constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// This Page will show order details also with profile details

const ProfilePage = () => {
  const navigate = useNavigate();
  const [openOtp, setOpenOtp] = useState(false);
  const [openProfile, setOpenProfile] = useState(true);
  const [mobileOTP, setmobileOTP] = useState("");
  const [email, setEmail] = useState("");
  const [emailValidError, setEmailValidError] = useState(false);
  var user = JSON.parse(localStorage.getItem("userData"))
  // updateProfileSendOtpApi api calling
  const updateProfileSendOtpApi_f = (e) => {
    e.preventDefault()
    updateProfileSendOtpApi(qs.stringify({ custId: user?.cust_id }))
      .then((response) => {
        console.log(response?.data?.messege);
        if (response?.data?.status == 1) {
          toast.success(response?.data?.messege, { toastId: "6" });
          setOpenOtp(true)
          setOpenProfile(false)
        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.message, { toastId: "5" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // updateProfileApi api calling
  const updateProfileApi_f = (e) => {
    e.preventDefault()
    if (email != "") {
      const rex = /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,12}[.]{1}[A-Za-z.]{2,6}$/;
      if (rex.test(email) === false) {
        toast.error("Please enter correct email.", { toastId: "3" });
        setEmailValidError(true);
        return;
      }
    }
    updateProfileApi(qs.stringify({
      altMobileNumber: '',
      custId: user?.cust_id,
      name: user?.user_name,
      emailId: email,
      otp: mobileOTP,
    }))
      .then((response) => {
        console.log(response?.data?.messege);
        if (response?.data?.status == 1) {
          toast.success(response?.data?.messege, { toastId: "6" });
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data)
          );
          localStorage.setItem(
            "userTokenOfBrbun",
            JSON.stringify(response?.data?.userToken)
          );
          localStorage.setItem(
            "cust_id",
            JSON.stringify(response?.data?.cust_id)
          );
          setOpenOtp(false)
          setOpenProfile(true)
        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.message, { toastId: "5" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Handle Mobile OTP
  const handleMobileOTP = (value) => {
    if (/^\d{0,}?$/.test(value)) {
      setmobileOTP(value);
    }
  };
  //  handle Email 
  const handleEmail = (value) => {
    const rex = /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,12}[.]{1}[A-Za-z.]{2,6}$/;
    if (value === "") {
      setEmailValidError(false);
    } else if (rex.test(value) === false) {
      setEmailValidError(true);
    } else {
      setEmailValidError(false);
    }
    setEmail(value);
  };
  useEffect(() => {
    user = JSON.parse(localStorage.getItem("userData"))
    setEmail(user?.email)
  }, [])
  return (
    <>
      <ToastContainer />
      <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => navigate(-1)}>
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
        <p className='text-xl font-bold text-white' >Update Profile</p>
      </div>

      {openProfile &&
        <>
          <div>
         
            <div className='flex flex-col mt-20  gap-3 mx-5'>
              <div className='flex gap-3 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 font-extrabold">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
                <div><p className='font-semibold'>Name</p>
                  <input type='text' placeholder='Name' value={user?.user_name} className='outline-none p-1 border-b border-black' disabled />
                </div></div>
              <div className='flex gap-3 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 font-extrabold">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
              </svg>
                <div><p className='font-semibold'>Mobile</p>
                  <input type='text' value={user?.mob_no} placeholder='Mobile' className='outline-none p-1 border-b border-black' disabled />
                </div></div>
              <div className='flex gap-3 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 font-extrabold">
                <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
              </svg>
                <div><p className='font-semibold'>Email</p>
                  <input type='text' value={email}
                    maxLength="40"
                    name="email"
                    onChange={(e) => handleEmail(e.target.value)}
                    placeholder='Email' className='outline-none p-1 border-b border-black' />
                </div></div>
            </div>
          </div>
          <div className='flex justify-center items-center '><button className='text-white text-base font-semibold p-2 m-6 bg-[#ca1e24] w-full mt-8' onClick={(e) => { updateProfileSendOtpApi_f(e);setmobileOTP('') }}>Update</button></div>
        </>
      }
      {openOtp &&
        <div>
          <div className="!flex !justify-end mt-20">
          </div>
          {/* <p className="!font-bold !text-2xl !text-center py-5">
            Signup
          </p> */}
          <p className=" !text-lg !text-center ">
            Enter OTP sent to your mobile
          </p>
          <div className="flex flex-col justify-center">
            <input type="text"
              maxLength="5"
              name="mobileOTP"
              value={mobileOTP}
              onChange={(e) => handleMobileOTP(e.target.value)}
              className=" border-2 mx-20 border-black w-1/2 !my-4 !p-2 !rounded-xl !font-bold" />
            <p className='flex justify-center items-center ' onClick={(e) => updateProfileSendOtpApi_f(e)} >Resend OTP</p>
            <div className='flex justify-center items-center '>
              <button className='text-white text-base font-semibold p-2 m-6 bg-[#ca1e24] w-full mt-8' onClick={(e) => updateProfileApi_f(e)}>Validate</button>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ProfilePage;