import jkl from "../../Assets/2b4f62d606d1b2bfba9ba9e5386fabb7.webp";
import { useNavigate, NavLink, useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from 'qs';
import { viewAllApi,FoodCategoryImage,FoodMenuImage,restaurantImage } from '../../utils/constant';

const ViewAll = () => {
  const Navigate = useNavigate();
  const typeOfUrl = useParams('type');
  const [viewAll, setViewAll] = useState([]);
  const userAddressLatLong = JSON.parse(localStorage.getItem("userAddressLatLong"));
  // ViewAll api calling
  const viewAll_f = () => {
      viewAllApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, }),typeOfUrl?.type)
      .then((response) => {
        console.log(response?.data?.restaurantList);
        setViewAll(response?.data?.restaurantList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Item = (id) => {
    Navigate(`/items/${id}`)
  }
  useEffect(() => {
    viewAll_f();
  }, []);
  return (
    <>
      <div>
        <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => Navigate(-1)}>
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
          </svg>
          <p className='text-xl font-bold text-white'>View All</p>
        </div>

        <div className='pt-1'>
          {viewAll &&
            viewAll?.map((view) => (
              <div className='flex gap-2 p-1' onClick={(e) => { Item(view?.restaurant_id) }}>
              <div><img src={`${restaurantImage}${view?.restaurant_image}`} className='w-30 h-20 rounded-md' /></div>
              <div><p className='text-base font-semibold'>{view?.restaurant_name}</p><p className='text-xs font semibold'>{view?.food_category_name}</p><p className='text-xs text-gray-400'>{view?.restaurant_type}</p><p className='text-xs text-gray-400'>{view?.distance} KM</p></div>
            </div>
            ))}
          
          
        </div>
      </div>
    </>
  )
}

export default ViewAll