import React from 'react';
import Search from "../../Assets/icons8-search-24.png";
import { useNavigate } from 'react-router-dom';

const SearchPage = () => {
  const navigate = useNavigate();
  
  return (
    <>
<div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={()=>navigate(-1)}>
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
</svg>
<p className='text-xl font-bold text-white'>Search</p>
        </div>

    <div className='flex gap-3 p-4 items-center justify-center w-full'>
        <img src={Search}/><input type='text' placeholder='Type your keyword here'className='border-b outline-none w-full p-2'/>
    </div>
    </>
  )
};

export default SearchPage