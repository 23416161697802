import { useNavigate, NavLink } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import qs from 'qs';
import Swal from 'sweetalert2';
import {
  bannerSliderApi, quickDeliveryRestaurantsApi, nearbyRestaurantsApi, bestRatedRestaurantsApi
  , featuredAdvertiseRestaurantsApi, restaurantImage, sliderImage, restaurantCategoryApi, FoodCategoryImage
  , logosImage, cartGetApi, user, cartClearAllApi
} from '../../utils/constant';


const HomePage = (props) => {
  const Navigate = useNavigate()
  const userAddressLatLong = JSON.parse(localStorage.getItem("userAddressLatLong"));

  // const All = () => {
  //   Navigate("/all")
  // }
  // const Search = () => {
  //   Navigate("/search")
  // }
  const [restaurantCategory, setRestaurantCategory] = useState([]);
  const [bannerSlider, setBannerSlider] = useState([]);
  const [quickDeliveryRestaurants, setQuickDeliveryRestaurants] = useState([]);
  const [featuredAdvertiseRestaurants, setFeaturedAdvertiseRestaurants] = useState([]);
  const [bestRatedRestaurants, setBestRatedRestaurants] = useState([]);
  const [nearbyRestaurants, setNearbyRestaurants] = useState([]);
  const [carttotal, setCarttotal] = useState([]);


  // restaurantCategory api calling
  const restaurantCategory_f = () => {
    restaurantCategoryApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, }))
      .then((response) => {
        setRestaurantCategory(response?.data?.categoryList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // bannerSlider api calling
  const bannerSlider_f = () => {
    bannerSliderApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, }))
      .then((response) => {
        setBannerSlider(response?.data?.sliderList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // quickDeliveryRestaurants api calling
  const quickDeliveryRestaurants_f = () => {
    quickDeliveryRestaurantsApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, }))
      .then((response) => {
        setQuickDeliveryRestaurants(response?.data?.restaurantList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // featuredAdvertiseRestaurants api calling
  const featuredAdvertiseRestaurants_f = () => {
    featuredAdvertiseRestaurantsApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, }))
      .then((response) => {
        setFeaturedAdvertiseRestaurants(response?.data?.restaurantList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // BestRatedRestaurants api calling
  const BestRatedRestaurants_f = () => {
    bestRatedRestaurantsApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, }))
      .then((response) => {
        setBestRatedRestaurants(response?.data?.restaurantList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // NearbyRestaurants api calling
  const NearbyRestaurants_f = () => {
    nearbyRestaurantsApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, }))
      .then((response) => {
        setNearbyRestaurants(response?.data?.restaurantList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // restaurantCateName 
  const restaurantCateName_f = (id) => {
    Navigate(`/catWiseRestaurant/${id}`)
  };
  const Getcart_f = () => {
    cartGetApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        setCarttotal(response?.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const cartClearAllApi_f = () => {
    cartClearAllApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        Getcart_f()
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Item = (id) => {
    console.log(!carttotal?.restaurant_id );
    if (!carttotal?.restaurant_id) {
      Navigate(`/items/${id}`)
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want cleart cart',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          cartClearAllApi_f()
        }
      })

    }
  }
  useEffect(() => {
    restaurantCategory_f();
    bannerSlider_f();
    quickDeliveryRestaurants_f();
    featuredAdvertiseRestaurants_f();
    BestRatedRestaurants_f();
    NearbyRestaurants_f();
    Getcart_f();
  }, []);





  return (
    <>
      {userAddressLatLong===null ?
      
      <div>
          <p className="text-center text-2xl  mt-4">Please Choose a  Location</p>
      <div className="flex justify-center ">
    <img src="https://cdn-icons-png.flaticon.com/512/8226/8226227.png" alt="" className="h-96 "/>
      </div>
       
         
      </div>
        :
        <div>
           <div className='flex gap-2 p-4 overflow-scroll bg-[#171a29]'>
         {restaurantCategory &&
           restaurantCategory?.map((restaurantCat) => (
             <>
              <img onClick={(e) => { restaurantCateName_f(restaurantCat?.food_category_id) }} src={`${FoodCategoryImage}${restaurantCat?.food_category_image}`} className='w-20 h-20 border rounded-full' />
             </>
           ))}
       </div>
 
       <div className='p-4 flex gap-3 overflow-scroll'>
         {bannerSlider &&
           bannerSlider?.map((bannerSlid) => (
             <img src={`${sliderImage}${bannerSlid?.banner_image}`} className='w-48 h-32 rounded' />
           ))}
       </div>
 
       <div className='lg:p-10 p-3'>
         <div className='flex justify-between pb-3 lg:px-20 px-2'>
           <div className='lg:text-2xl text-lg font-bold text-gray-600'><p>Quick Delivery</p></div>
           <div className='lg:text-2xl text-lg font-bold text-[#cf1f26]'><NavLink to={`all/quickDeliveryRestaurants`}>View all</NavLink></div>
         </div>
         <div className='border border-b border-gray-200 flex justify-center items-center lg:mx-20 mx-2'></div>
 
         <div className='lg:p-10 p-2 lg:px-20 px-0 grid lg:grid-cols-4 grid-cols-3'>
           {quickDeliveryRestaurants &&
             quickDeliveryRestaurants?.slice(0, 9)?.map((restaurant) => (
               <div className=' border border-gray-200 shadow-xl m-2 mx-1 rounded hover:scale-110 hover:duration-300 delay-75' onClick={(e) => { Item(restaurant?.restaurant_id) }}>
                 <div><img src={restaurant?.restaurant_image != "" && restaurant?.restaurant_image != "NA" ? `${restaurantImage}${restaurant?.restaurant_image}` : logosImage} /></div>
                 <div className='text-xs text-gray-900 p-1'><p>{restaurant?.restaurant_name}</p></div>
               </div>
             ))}
         </div>
 
         <div className='flex justify-between pb-3 lg:px-20 px-0'>
           <div className='lg:text-2xl text-lg font-bold text-gray-600'><p>Featured/Advertised</p></div>
           <div className='lg:text-2xl text-lg font-bold text-[#cf1f26]'><NavLink to={`all/featuredAdvertiseRestaurants`}>View all</NavLink></div>
         </div>
         <div className='border border-b border-gray-200 flex justify-center items-center lg:mx-20'></div>
 
         <div className='lg:p-10 p-2 lg:px-20 px-0 grid lg:grid-cols-4 grid-cols-3'>
           {featuredAdvertiseRestaurants &&
             featuredAdvertiseRestaurants?.slice(0, 9)?.map((featuredAdvertiseRestaurant) => (
               <div className=' border border-gray-200 shadow-xl m-2 mx-1 rounded hover:scale-110 hover:duration-300 delay-75' onClick={(e) => { Item(featuredAdvertiseRestaurant?.restaurant_id) }}>
                 <div><img src={featuredAdvertiseRestaurant?.restaurant_image != "" && featuredAdvertiseRestaurant?.restaurant_image != "NA" ? `${restaurantImage}${featuredAdvertiseRestaurant?.restaurant_image}` : logosImage} /></div>
                 <div className='text-xs text-gray-900 p-1'><p>{featuredAdvertiseRestaurant?.restaurant_name}</p></div>
               </div>
             ))}
         </div>
 
 
         <div className='flex justify-between pb-3 lg:px-20 px-2'>
           <div className='lg:text-2xl text-lg font-bold text-gray-600'><p>Best Rated Restaurants</p></div>
           <div className='lg:text-2xl text-lg font-bold text-[#cf1f26]'><NavLink to={`all/bestRatedRestaurants`}>View all</NavLink></div>
         </div>
         <div className='border border-b border-gray-200 flex justify-center items-center lg:mx-20'></div>
 
         <div className='lg:p-10 p-2 lg:px-20 px-0 grid lg:grid-cols-4 grid-cols-3'>
           {bestRatedRestaurants &&
             bestRatedRestaurants?.slice(0, 9)?.map((bestRatedRestaurant) => (
               <div className=' border border-gray-200 shadow-xl m-2 mx-1 rounded hover:scale-110 hover:duration-300 delay-75' onClick={(e) => { Item(bestRatedRestaurant?.restaurant_id) }}>
                 <div><img src={bestRatedRestaurant?.restaurant_image != "" && bestRatedRestaurant?.restaurant_image != "NA" ? `${restaurantImage}${bestRatedRestaurant?.restaurant_image}` : logosImage} /></div>
                 <div className='text-xs text-gray-900 p-1'><p>{bestRatedRestaurant?.restaurant_name}</p></div>
               </div>
             ))}
         </div>
 
 
         <div className='flex justify-between pb-3 lg:px-20 px-2'>
           <div className='lg:text-2xl text-lg font-bold text-gray-600'><p>Nearby Restaurants</p></div>
           <div className='lg:text-2xl text-lg font-bold text-[#cf1f26]'><NavLink to={`all/nearbyRestaurants`}>View all</NavLink></div>
         </div>
         <div className='border border-b border-gray-200 flex justify-center items-center lg:mx-20'></div>
 
         <div className='pt-3'>
           {nearbyRestaurants &&
             nearbyRestaurants?.slice(0, 16)?.map((nearbyRestaurant) => (
               <div className='flex gap-2 p-1' onClick={(e) => { Item(nearbyRestaurant?.restaurant_id) }}>
                 <div><img src={nearbyRestaurant?.restaurant_image != "" && nearbyRestaurant?.restaurant_image != "NA" ? `${restaurantImage}${nearbyRestaurant?.restaurant_image}` : logosImage} className='w-30 h-20 rounded-md' /></div>
                 <div><p className='text-base font-semibold'>{nearbyRestaurant?.restaurant_name}</p><p className='text-xs font semibold'>{nearbyRestaurant?.food_category_name}</p><p className='text-xs text-gray-400'>{nearbyRestaurant?.restaurant_type}</p><p className='text-xs text-gray-400'>{nearbyRestaurant?.distance?.slice(0, 5)} KM</p></div>
               </div>
             ))}
         </div>
 
       </div>
        </div>
      }

    </>
  );
}

export default HomePage;