import { useNavigate, NavLink, useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import phone from "../../Assets/pngwing.com (14).png";
import { get_help_contactApi } from '../../utils/constant';
import qs from 'qs';

const Help = () => {
  const navigate = useNavigate()
  const [help, sethelp] = useState([]);

  const help_f = () => {
    get_help_contactApi(qs.stringify({  }))
      .then((response) => {
        sethelp(response.data.contact_list)
      })
      .catch((error) => {
        console.log(error);
      });
  };
useEffect(()=>{
  help_f();
},[])
  return (
    <>

      <div className='flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white font-extrabold text-xl" onClick={() => navigate(-1)}>
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
        <p className='text-xl font-bold text-white'>Help</p>
      </div>

      <div className='flex justify-center items-center'>
        <div className='text-2xl flex flex-col justify-center items center pt-10'>
          <div className='text-2xl text-gray-500 py-4 font-bold'><p>Need Help ?<br /> You Can Call Us At</p></div>
        </div>
      </div>
      {help?.map((item)=>{
            return<>
              <div className="mx-5  flex gap-1 p-3 shadow-lg rounded-md px-5 bg-gray-100 my-8 text-lg font-bold text-gray-600 hover:scale-125 transition transition-duration-2000">
              <img src={phone} className="w-7 h-7" />
              <p>{item?.contact_no}</p>
            </div>
          
            </>
         })}
    </>
  )
}

export default Help;