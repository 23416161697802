import { Navigate, Outlet ,useLocation} from "react-router-dom";

const PrivateRoutes = () => {
  let  userid = localStorage.getItem("userTokenOfBrbun") == null ? false : true;
//   console.log("Hi this is user id" , userid);
    return (
        <>
            {userid ? <Outlet  /> : <Navigate to="/login" />};
        </>
    )
  };

  export default PrivateRoutes;