import React from "react";
import abc from "../../Assets/icons8-location-30.png";
import bcd from "../../Assets/ef3i0k1ilhdsistcykog.webp";
import veg from "../../Assets/icons8-vegetarian-food-symbol-48.png";
import offer from "../../Assets/icons8-discount-24.png";
import upi from "../../Assets/icons8-bhim-upi-50.png";
import card from "../../Assets/icons8-debit-card-50.png";
import wallet from "../../Assets/icons8-wallet-30.png";
import banking from "../../Assets/icons8-banking-24.png";
import upiimg from "../../Assets/pngwing.com (12).png";
import phonepeimg from "../../Assets/PngItem_38170.png";
import paytmpng from "../../Assets/pngwing.com (13).png";
import apayimg from "../../Assets/6220a986912013c51947f9b7.png";
import { useNavigate } from "react-router-dom";

const PaymentPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex gap-5 bg-[#ca1e24] p-4 text-white items-center sticky top-0 z-50 shadow-md">
        
        
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-white font-extrabold text-xl"
            onClick={()=>navigate(-1)}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
          <p className="text-xl font-bold text-white">Payment</p>
        
      </div>

      <div className="bg-[#e9ecee] lg:px-32 lg:py-8 lg:flex-row flex flex-col gap-2">
        <div className="lg:w-2/3 w-full">
          <div className="bg-white shadow-lg">
            <div className="p-5">
              <div className="py-2 flex justify-between">
                <p className="text-xl font-semibold text-gray-800">
                  Delivery address
                </p>
                <p className="text-lg text-orange-500 font-semibold">Change</p>
              </div>
              <div className="font-semibold">Others</div>
              <div>
                {" "}
                <p className="text-sm font-light text-gray-400 pb-5">
                  6 19/c Charbagh, Lucknow, Uttar Pradesh 226010, India
                </p>
              </div>
              <div>
                <p className="text-base font-semibold">32 MINS</p>
              </div>
              {/* <div className='flex gap-5'>
                    <div className='flex gap-4 p-3 border border-gray-300 w-1/2 hover:shadow-xl'>
                        <div><img src={abc}/></div>
                        <div>
                            <p className='text-base font-semibold'>Others</p>
                            <p className='text-sm font-light text-gray-400 pb-5'>6 19/c Charbagh, Lucknow,<br/> Uttar Pradesh 226010, India</p>
                            <p className='text-base text-gray-500'>35 MINS</p>
                            <button className='text-white font-semibold px-4 py-2 bg-[#60b246] rounded my-2'>DELIVER HERE</button>
                        </div>
                    </div>
                    <div className='flex gap-4 p-3 border border-dashed border-gray-300 w-1/2 hover:shadow-xl'>
                    <div><img src={abc}/></div>
                        <div>
                            <p className='text-base font-semibold'>Add New Address</p>
                            <p className='text-sm font-light text-gray-400 pb-12'>6 19/c Charbagh, Lucknow,<br/> Uttar Pradesh 226010, India</p>
                            <button className='font-semibold px-4 py-2 border border-[#60b246] text-[#60b246] rounded'>Add New</button>
                        </div>
                    </div>
                </div> */}
            </div>
          </div>
          <div className="bg-white pb-5 ">
            <div className="text-xl font-semibold text-gray-700 mt-2 bg-white px-8 py-6">
              <p>Choose Payment Method</p>
            </div>

            <div className="lg:px-8 px-3 flex gap-4">
              <div className="lg:w-1/3 w-full bg-[#edf1f7] pl-2 py-2">
                <div className="flex gap-2 lg:p-4 p-2 ml-2 mt-2 bg-white hover:bg-white items-center">
                  <img src={upi} className="w-8 h-8" />
                  <p className="text-lg font-semibold text-gray-600">UPI</p>
                </div>
                <div className="flex gap-2 lg:p-4 p-2 ml-2 mb-2 hover:bg-white items-center">
                  <img src={card} className="w-8 h-8" />
                  <p className="text-lg font-semibold text-gray-600">Cards</p>
                </div>
                <div className="flex gap-2 lg:p-4 p-2 ml-2 hover:bg-white items-center">
                  <img src={banking} className="w-8 h-8" />
                  <p className="text-lg font-semibold text-gray-600">
                    Net Banking
                  </p>
                </div>
                <div className="flex gap-2 lg:p-4 p-2 ml-2 hover:bg-white items-center">
                  <img src={wallet} className="w-8 h-8" />
                  <p className="text-lg font-semibold text-gray-600">COD</p>
                </div>
              </div>

              <div className="lg:w-2/3 w-full">
                <div className="border p-5 mb-5 hover:shadow-md">
                  <img src={upiimg} className="p-3 w-16" />
                  <p className="lg:text-lg text-sm font-semibold py-2">
                    Google Pay
                  </p>
                  <button className="lg:text-base text-sm font-semibold text-white lg:py-2 py-1 lg:px-4 px-2 bg-[#60b246] hover:shadow-md">
                    Pay Rs.876
                  </button>
                </div>

                <div className="border p-5 mb-5 hover:shadow-md">
                  <img src={phonepeimg} className="p-3 w-16" />
                  <p className="lg:text-lg text-sm font-semibold py-2">
                    Phone Pay
                  </p>
                  <button className="lg:text-base text-sm font-semibold text-white lg:py-2 py-1 lg:px-4 px-2 bg-[#60b246] hover:shadow-md">
                    Pay Rs.876
                  </button>
                </div>

                <div className="border p-5 hover:shadow-md">
                  <img src={paytmpng} className="p-3 w-16 -pl-4" />
                  <p className="lg:text-lg text-sm font-semibold py-2">PayTM</p>
                  <button className="lg:text-base text-sm font-semibold text-white lg:py-2 py-1 lg:px-4 px-2 bg-[#60b246] hover:shadow-md">
                    Pay Rs.876
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='lg:w-1/3 w-full bg-white px-7 max-h-[450px] overflow-scroll'>
            <div className='flex gap-4 py-4 pb-2 sticky top-0 bg-white'>
                <div><img src={bcd} className='w-14'/></div>
                <div className='border-b border-black'><p className='text-xl font-semibold'>KFC</p><p className='text-sm text-gray-500'>Hazratganj</p></div>
            </div>
            <div className='flex gap-2 items-center py-3 justify-around'>
                <img src={veg} className='w-3 h-3'/>
                <p className='text-xs text-gray-500'>6pc Hot & Crispy with<br/> Dynamite Sauce</p>
                <div className='flex gap-2 border px-1 text-xs'><button>-</button><button>1</button><button>+</button></div>
                <p className='text-xs'>Rs.867</p>
            </div>

            <div className='p-3 text-base w-full border border-dashed border-gray-300 my-2 text-gray-500 flex gap-2 items-center hover:shadow-lg'><img src={offer}/><p>Apply Coupon</p></div>
            <div className='text-sm font-semibold py-2'><p>Bill Details</p></div>
            <div className='flex justify-between text-sm text-gray-500 py-2'>
                <p>Item Total</p>  <p>Rs.876</p>
            </div>
            <div className='flex justify-between text-sm text-gray-500 py-2'>
                <p>Delivery Fee</p>  <p>Rs.0</p>
            </div>
            <div className='border-dashed border-b my-2 border-gray-300'></div>

            <div className='flex justify-between text-sm text-gray-500 py-2'>
                <p>GST & Service charges</p>  <p>Rs.40</p>
            </div>

            <div className='border border-b my-3 border-gray-500'></div>
            <div className='flex justify-between font-semibold py-2 sticky top-0 z-50'>
                <p>To Pay</p>  <p>Rs.876</p>
            </div>
            <div className='border border-b mt-3 border-gray-500'></div>
        </div> */}
      </div>
    </>
  );
};

export default PaymentPage;
